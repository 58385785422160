
import { authMapGetters, authMapState } from "@/store/modules/auth";
import { getUserCompanies, getCompanyPrefix } from "@/helpers/companies";
import {
  claimsMapActions,
  claimsMapMutations,
  claimsMapState
} from "@/store/modules/claims";
import { isAfter } from "date-fns";

import Vue from "vue";
export default Vue.extend({
  name: "BillingForm",
  components: {
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        policyNumber: "",
        companyPrefix: "",
        companyNumber: "",
        category: "claims",
        site: "ATLAS",
        billingType: "summary"
      },
      userCompanies: getUserCompanies("billing"),
      companyPrefixOption: [],
      isCompanyChecked: false,
      isCompanyPrefixChecked: false,
      errorMessage: "",
      successMessage: ""
    };
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    ...authMapState(["initData"]),
    ...claimsMapState(["makingApiRequest"]),
    disableButtons(): boolean {
      return (
        !this.form.companyPrefix ||
        !this.form.companyNumber ||
        !this.form.policyNumber
      );
    },
    pickerOptions() {
      return {
        disabledDate: (d: Date) => isAfter(d, new Date())
      };
    }
  },
  methods: {
    ...claimsMapActions(["getLossData"]),
    ...claimsMapMutations(["SET_EDIT", "SET_STATES"]),
    ...claimsMapActions(["getFiservData"]),
    changeValue(key: string, event: any) {
      (this.form as any)[key] = event;
      this.$emit("saveData", this.form);
    },
    resetFields() {
      this.form = {
        policyNumber: "",
        companyPrefix: "",
        companyNumber: "",
        category: "claims",
        site: "ATLAS",
        billingType: "summary"
      };
    }
  },
  watch: {
    "form.companyNumber": {
      handler(newValue: any) {
        (this.companyPrefixOption as any) = getCompanyPrefix(
          this.userCompanies,
          newValue
        );
        this.form.companyPrefix = "";
      }
    }
  }
});
